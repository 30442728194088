import React, { useState, useEffect } from 'react';
import './Signup.css';
import { useRegisterUserMutation } from '../../services/userAuthApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Term from '../Term';
import Papa from 'papaparse';
import restrictedDomainsFile from '../../Email/free-email-providers.csv';
import countrydata from '../../CountryInfo/countryinfo.json';

function Signup({ onClose, onSigninClick }) {
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        country: '',
        area_of_interest: '',
        mob: '',
        lan: '', // Landline number
        password: '',
        confirm_password: '',
        agreeToTerms: false // Add a state for tracking whether the user has agreed to the terms
    });

    const [countryList, setCountryList] = useState([]);
    const [showTerms, setShowTerms] = useState(false); // State to manage the visibility of the Terms component
    const [validPassword, setValidPassword] = useState(false); // State to track if the password is valid
    const [passwordMatch, setPasswordMatch] = useState(true); // State to track if the password and confirm password match
    const [mobcheck, setMobCheck] = useState(true);
    const [lancheck, setLanCheck] = useState(true);
    const [emailcheck, setEmailCheck] = useState(true);
    const [interestcheck, setInterestCheck] = useState(true);

    const [registerUser, { isLoading }] = useRegisterUserMutation();
    const [restrictedDomains, setRestrictedDomains] = useState([]);

    // Load and parse the CSV file
    useEffect(() => {
        fetch(restrictedDomainsFile)
            .then(response => response.text())
            .then(csvText => {
            

                Papa.parse(csvText, {
                    header: true,
                    complete: (results) => {
                        const domains = results.data.map(row => row.domain);
                        setRestrictedDomains(domains);
                    },
                    error: (error) => {
        
                    }
                });
            })
            .catch(error => {
        
            });
    }, []);

    // Set country list from local JSON data
    useEffect(() => {
        const countries = countrydata.map(country => ({
            code: country.code,
            name: country.name,
            dial_code: country.dial_code
        }));
        setCountryList(countries);
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));

        if (name === 'password') {
            const isValid = value.length >= 8 && !/^\d+$/.test(value);
            setValidPassword(isValid);
        }

        if (name === 'password2') {
            setPasswordMatch(formData.password === value);
        }

        // Mobile validation
        if (name === 'mob') {
            const mobRegex = /^[0-9]{10}$/;
            setMobCheck(mobRegex.test(value) || value.trim() === '');
        }

        // Landline validation
        if (name === 'lan') {
            const lanRegex = /^[0-9]{10,}$/;
            setLanCheck(lanRegex.test(value) || value.trim() === '');
        }

        // Email validation
        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setEmailCheck(emailRegex.test(value) || value.trim() === '');
        }

        // Interest validation
        if (name === 'area_of_interest') {
            setInterestCheck(value.length <= 250);
        }
    };

    useEffect(() => {
        setPasswordMatch(formData.password === formData.confirm_password);
    }, [formData.password, formData.confirm_password]);

    const handleSubmit = async () => {
        try {
            // Check for mandatory fields
            const mandatoryFields = ['full_name', 'country', 'area_of_interest', 'mob', 'email', 'password', 'password2'];
            const emptyFields = mandatoryFields.filter(field => !formData[field]);

            if (emptyFields.length > 0) {
                const emptyFieldsNames = {
                    mob: 'Mobile Number',
                    password2: 'Confirm Password',
                    area_of_interest: 'Area of Interest in Aviation'
                };

                const emptyFieldsString = emptyFields.map(field => emptyFieldsNames[field] || field.replace('_', ' ')).join(', ');
                toast.error(`Please fill the following mandatory fields first: ${emptyFieldsString}.`);
                return;
            }

            // Check if terms are agreed
            if (!formData.agreeToTerms) {
                toast.error('Please agree to the Terms of Service.');
                return;
            }

            // Check if the email domain is restricted
            const emailDomain = formData.email.split('@')[1];
            if (restrictedDomains.includes(emailDomain)) {
                toast.error('Please enter your company-associated email. Email addresses from publicly available domains are restricted.');
                return;
            }

            // Proceed with registration if all mandatory fields are filled
            const res = await registerUser(formData);

            if (res.error) {
                if (res.error.data.errors.email && res.error.data.errors.email.length > 0) {
                    toast.error(res.error.data.errors.email[0]); // Display specific email error message
                } else {
                    toast.error(res.error.data.detail || 'Something went wrong'); // Display general error message if registration fails
                }
                return;
            }

            toast.success('An email has been sent to activate your account. Please proceed to activate your account using the link provided in the email.');
            onSigninClick(); // Proceed to signin after successful registration
        } catch (error) {
            toast.error('Error during registration:', error.message); // Display error toast for any unexpected error during registration
        }
    };

    const handleCloseClick = () => {
        onClose();
    };

    const handleSigninButtonClick = () => {
        onSigninClick();
    };

    const handleCloseTerms = () => {
        setShowTerms(false);
    };

    const handleTermsButtonClick = () => {
        setShowTerms(true); // Show the Terms component when the button is clicked
    };

    return (
        <div className="signup-container">
            {showTerms ? (
                <Term onClose={handleCloseTerms} />
            ) : (
                <>
                    <div className="signup-form">
                        <h2>Sign Up</h2>
                        <span>*: Mandatory Field</span>
                        <div className='scroll-div'>
                            <div className="input-div">
                                <input
                                    type="text"
                                    placeholder="Name*"
                                    className="signup-input"
                                    name="full_name"
                                    value={formData.full_name}
                                    onChange={handleChange}
                                />
                                <select
                                    className="signup-input"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                >
                                   <option value="" disabled selected>Select Country*</option>
                                    {countryList.map((country, index) => (
                                        <option key={index} value={country.name}>{country.name}( {country.dial_code})</option>
                                    ))}
                                </select>
                                <textarea
                                    placeholder="Area of Interest in Aviation* (max 250 characters)"
                                    className="signup-input"
                                    name="area_of_interest"
                                    value={formData.area_of_interest}
                                    onChange={handleChange}
                                    style={{ height: "80px" }}
                                    maxLength={250}
                                />
                                {!interestcheck && (
                                    <span className="error-message">Exceeds 250 characters limit.</span>
                                )}
                                <input
                                    type="text"
                                    placeholder="Mobile*"
                                    className="signup-input"
                                    name="mob"
                                    value={formData.mob}
                                    onChange={handleChange}
                                />
                                {!mobcheck && (
                                    <span className="error-message">Please enter a valid 10-digit mobile number.</span>
                                )}
                                <input
                                    type="text"
                                    placeholder="Landline Number"
                                    className="signup-input"
                                    name="lan"
                                    value={formData.lan}
                                    onChange={handleChange}
                                />
                                {!lancheck && (
                                    <span className="error-message">Please enter a valid 10-digit Landline number.</span>
                                )}
                                <input
                                    type="email"
                                    placeholder="Company Email*"
                                    className="signup-input"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {!emailcheck && (
                                    <span className="error-message">Please enter a valid email address.</span>
                                )}
                                <input
                                    type="password"
                                    placeholder="Password*"
                                    className="signup-input"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                {formData.password.length > 0 && !validPassword && (
                                    <span className="error-message">Password must be at least 8 characters long and contain letters and numbers.</span>
                                )}
                                <input
                                    type="password"
                                    placeholder="Confirm Password*"
                                    className="signup-input"
                                    name="password2"
                                    value={formData.password2}
                                    onChange={handleChange}
                                    disabled={!validPassword}
                                />
                                {!passwordMatch && (
                                    <span className="error-message">Passwords do not match.</span>
                                )}
                                <label>
                                    <input
                                        type="checkbox"
                                        name="agreeToTerms"
                                        checked={formData.agreeToTerms}
                                        onChange={handleChange}
                                    /> I have read and agree to the <button className='term-button' onClick={handleTermsButtonClick}> Terms of Service</button>
                                </label>
                            </div>
                            
                        </div>
                        <button className="signup-button" onClick={handleSubmit} disabled={isLoading}>
                            {isLoading ? 'Creating Account...' : 'Create Account'}
                        </button>
                       
                        <p>Already have an account? </p>
                        <button className="signin-button" onClick={handleSigninButtonClick}>
                            <i className="fa fa-sign-in"></i> Sign In
                        </button>
                   
                    </div>
                  
                    <button className="close-button" onClick={handleCloseClick}>
                        <i className="fa fa-times"></i>
                    </button>
                </>
            )}
        </div>
    );
}

export default Signup;
