import './EmailVerification.css';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActivateUserMutation } from "../../services/userAuthApi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EmailVerification() {
  const [activateUser] = useActivateUserMutation();
  const { uid, token } = useParams();

  useEffect(() => {
    const activateAccount = async () => {
      try {
        const res = await activateUser({ uid, token });
        if (res.error) {
        
          toast.error('An error occurred. Please try again.');
        } else {
          const success = res.data; // Assuming the API response returns a boolean indicating success
          if (success) {
            toast.success('Account activated successfully.');
            // Redirect to login page or any other page as needed
          } else {
            toast.error('Failed to activate account. Please try again.');
          }
        }
      } catch (error) {
    
        toast.error('An error occurred. Please try again.');
      }
    };
    activateAccount();
  }, [activateUser, uid, token]);


}

export default EmailVerification;
