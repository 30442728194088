const storeToken = (value) => {
  if (value) {
    const { access, refresh } = value;
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
  }
};

const getToken = () => {
  let access_token = localStorage.getItem('access_token');
  let refresh_token = localStorage.getItem('refresh_token');
  return { access_token, refresh_token };
};

const removeToken = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

const checkAndRemoveExpiredToken = () => {
  const { access_token } = getToken();
  if (isAccessTokenExpired(access_token)) {
    removeToken();
  }
};

const isAccessTokenExpired = (access_token) => {
  if (!access_token) {
    return true; // Token is considered expired if it doesn't exist
  }

  const tokenParts = access_token.split('.');
  if (tokenParts.length !== 3) {
    return true; // Invalid token format
  }

  const jwtPayload = JSON.parse(atob(tokenParts[1])); // Decode the payload
  if (!jwtPayload.exp) {
    return false; // Token doesn't have an expiration claim, so assume it's not expired
  }

  const expirationTime = jwtPayload.exp * 1000; // Convert to milliseconds
  const currentTime = Date.now();

  return expirationTime < currentTime;
};

export { storeToken, getToken, removeToken, checkAndRemoveExpiredToken };
