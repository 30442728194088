import './Santa_Cruz_Aerodrome.css'

import React from 'react'

function Santa_Cruz_Aerodrome() {
  return (
    <div className='info_SC'>
      <p>Terrain and Obstacle data has been created using data mining from all the revised NOC’s  issued for enhanced height through Aeronautical Studies for Santa Cruz Aerodrome in the years 2018 - 2023 as well as the obstacles published in the AIP. Since an Aeronautical Study is only carried out when a proposed structure penetrates the OLS, all such structures/buildings qualify for being part of the obstacle database as stated in Sec 5.3.3.4.5, CAR Series I Part I.</p>
      <p>All the NOC’s for which enhanced height has been granted through the process of Appeal and Aeronautical Studies as published in the MOM of the Appeal Committee as well as the AIP have been collected and plotted in a GIS environment. The digital data thus created has been overlaid on Cartosat-3 Mx geo-referenced satellite imagery with a resolution of 1.12m. All visible building footprints have also been captured. The distance of the nearest coordinate of each NOC polygon  + Obstacle listed in the AIP has been calculated from the Runway end as well as Runway strip to calculate the penetration of the OLS surface and the TOD Obstacle collection surface.  All this data has been tabulated and linked to the NOC polygons + Obstacles published in the AIP. This meets the requirement of TOD as required by CAR Series I Part I.</p>
    </div>
  )
}

export default Santa_Cruz_Aerodrome
