import React, { useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import LandingPage from './components/LangingPage';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import ResetPassword from './components/auth/ResetPassword';
import EmailVerification from './components/auth/EmailVerification';
import About from './components/About';
import { checkAndRemoveExpiredToken } from './services/LocalStorageService'



import { useSelector } from "react-redux";
import seoData from './SEO/seoData.json'
import { Helmet } from 'react-helmet';


function App() {
  const { access_token } = useSelector(state => state.auth);
  const [isAuthActive, setIsAuthActive] = useState(false);
  const [SignInState, setSignInState] = useState(false);
  const [data, setData] = useState(null);


  useEffect(() => {
    // Simulating fetching the JSON data
    setData(seoData.geoportal);
   
  }, []);
    // Check if access token exists in browser storage
    const access_token_stored = localStorage.getItem('access_token');
    useEffect(() => {
      // Check for expired tokens when component mounts
      checkAndRemoveExpiredToken();
    }, []);
   

  const handleAuthActive = (isActive) => {
    setIsAuthActive(isActive);
  };

  const hadleSignInState =(SignInStateValue)=>
  {
    setSignInState(SignInStateValue)
    

  }

  return (
    <Router>
      <div className={`App ${isAuthActive ? 'auth-active' : ''}`}>
      <Helmet>
          <title>{data?.title}</title>
          <meta name="description" content={data?.description} />
          <meta name="keywords" content={data?.keywords?.join(',')} />
          <meta property="og:title" content={data?.ogTitle} />
          <meta property="og:description" content={data?.ogDescription} />
          <meta property="og:image" content={data?.ogImage} />
          <meta name="twitter:title" content={data?.twitterTitle} />
          <meta name="twitter:description" content={data?.twitterDescription} />
          <meta name="twitter:image" content={data?.twitterImage} />
        </Helmet>
        <Header setAuthActive={handleAuthActive} SignInState={SignInState}/>
        <Routes>
          <Route path="/" element={<LandingPage isAuthActive={isAuthActive} OnSignInState={hadleSignInState} />} />
          <Route path='/about' element={<About/>}></Route>
          <Route
            path="/dashboard"
            element={access_token||access_token_stored ? <Dashboard /> : <Navigate to="/" />}
          />
      
           <Route path="api/user/reset/:id/:token" element={<ResetPassword />} />
           <Route path="/email-verification/:uid/:token" element={<EmailVerification />} />
        </Routes>
       
        <Footer />
      </div>
    </Router>
  );
}

export default App;
