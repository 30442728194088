import './IGI_Aerodrome.css'
import React from 'react'

function IGI_Aerodrome() {
  return (
    <div className='info_IGI'>
      <p>Terrain and Obstacle data has been created using stereo satellite imagery ( 50cm resolution) for a pilot area by extracting the x,y,z of all existing obstacles within the AOI available on the collection date of the satellite imagery. Following the creation of the Obstacle database in real earth coordinates, the authenticity of the data has been checked on ground. The existing heights issued in the NOC’s are also attached to the extracted obstacles, all obstacles with a Top Elevation more than that issued in the NOC can therefore be detected. This gives an intelligent birds’ eye view of the current situation which can be updated periodically in a relatively short time with less investment compared to a manual survey. </p>
    </div>
  )
}

export default IGI_Aerodrome
