import React, { useState } from 'react';
import './SendPasswordResetEmail.css';
import { useSendPasswordResetEmailMutation } from '../../services/userAuthApi';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SendPasswordResetEmail({ onClose }) {
  const [email, setEmail] = useState('');
  const [sendPasswordResetEmail] = useSendPasswordResetEmailMutation();

  const handleCloseClick = () => {
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate email
    if (!validateEmail(email)) {
      toast.error('Please enter a valid email address.');
      return;
    }

    // Check if email is empty
    if (!email) {
      toast.error('Please enter an email address.');
      return;
    }

    try {
      const response = await toast.promise(
        sendPasswordResetEmail({ email }),
        {
          pending: 'Sending...'
        } // Only show pending message here
      );



      // Check for errors and display appropriate toasts
      if (!response.error) {
        toast.success('Reset link has been sent to email'); // Success message
      } else if (response.error.status === 'FETCH_ERROR') {
        toast.error('Network error occurred. Please check your connection and try again.');
      } else {
        toast.error('User does not exist with this email! Please sign up first'); // General error message
      }

    } catch (error) {
      
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="send-password-reset-email">
      <h2>Forgot Password?</h2>
      <div className='password-reset-message'>
        <p>Please enter the email address you used during registration. We'll send password reset instructions shortly. Reset assured, we never store or send passwords via email for security reasons.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='input-div'>
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="forget-password-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="submit" className="forget-password-button">
          Send Reset Link
        </button>
      </form>
     
      <button className="close-button" onClick={handleCloseClick}>
        <i className="fa fa-times"></i>
      </button>
    </div>
  );
}

export default SendPasswordResetEmail;
