// About.js

import React from 'react';
import './About.css';
import TINImage from '../images/aboutpagepic1.jpg';
import FlyImage from '../images/aboutpagepic.png';

function About() {
  return (
    <div className="about-container">
      <h2>About</h2>
    <span>“Like failure, chaos contains information that can lead to knowledge -- even wisdom. Like art.“ Toni Morrison</span>
      <div className="information">
        <h3>Aeronautical Data Chain</h3>
        <p>
          A key driver for the transition from AIS to AIM is the need to achieve an uninterrupted aeronautical data chain with no loss or corruption in information, in a pre-defined format and with guaranteed accuracy and integrity. The aeronautical data chain is defined as “a series of interrelated links wherein each link provides a function that facilitates the origination, transmission and use of aeronautical data for a specific purpose”.
        </p>
        <div className="fly-image">
        <img src={ FlyImage} alt="Terrain and Obstacle Data" className="fly-img" />
      </div>
      <p>
Obstacles penetrating the Annexure 14 surfaces (specifically in the Approach Funnel and IHS ) may affect air safety if not properly controlled. TOD is required for monitoring and surveillance of obstacles for ensuring flight safety. An accurate digital database of Obstacles around the Aerodrome needs to be created and updated at regular intervals as per DGCA Guidelines.
</p>
      </div>


      <div className="terrain-data">
        <h3>Terrain Data</h3>
        <p>
          <strong>Shall / Mandatory</strong>
          CIVIL AVIATION REQUIREMENTS SERIES I PART I SECTION 9, 8th JANUARY 2010, Rev. 6, 20th September 2021
        </p>
        <p>
          <strong>Sec 5.3.3.2:</strong> For aerodromes regularly used by international civil aviation, terrain data shall be provided for:
          <ul>
            <li>Area 1</li>
            <li>Area 2a</li>
            <li>Take-off flight path Area</li>
            <li>An area bounded by the lateral extents of the aerodrome obstacle limitation surfaces.</li>
          </ul>
        </p>
        <p>
          <strong>Sec 5.3.3.3.7:</strong> For aerodromes regularly used by international civil aviation, terrain data shall be provided for Area 4, for all runways where precision approach category II or III operations have been established and where detailed terrain information is required by operators to enable them to assess the effect of terrain on decision height determination by use of radio altimeters. 
          [Sets of electronic terrain data shall include spatial ( position and elevation), thematic and temporal aspects for the surface of the Earth containing naturally occurring features such as mountains, hills, ridges, valleys, bodies of water, permanent ice and snow, excluding obstacles. This shall represent the continuous surface that exists at the bare earth, the top of the canopy or something in between, also known as the ‘first reflective surface’.]
        </p>
      </div>

      <div className="obstacle-data">
        <h3>Obstacle Data</h3>
        <p>
          <strong>Shall / Mandatory</strong>
          CIVIL AVIATION REQUIREMENTS SERIES I PART I SECTION 9, 8th JANUARY 2010, Rev. 6, 20th September 2021
        </p>
        <p>
          <strong>Sec 5.3.3.4.1:</strong> Obstacle data sets shall contain the digital representation of the vertical and horizontal extent of obstacles.
        </p>
        <p>
          <strong>Sec 5.3.3.4.3:</strong> The obstacle data shall be provided for obstacles in Area 1 whose height is 100m or higher above ground.
        </p>
        <p>
          <strong>Sec 5.3.3.4.4:</strong> For aerodromes regularly used by international civil aviation, obstacle data shall be provided for all obstacles within Area 2 that are assessed as being a hazard to air navigation.
        </p>
        <p>
          <strong>Sec 5.3.3.4.5:</strong> For aerodromes regularly used by international civil aviation, obstacle data shall be provided for:
          <ul>
            <li>Area 2a: for those obstacles that penetrate an obstacle data collection surface outlined by a rectangular area around a runway that comprises the runway strip plus any clearway that exists. The Area 2a obstacle collection surface shall have height of 3 m above the nearest runway elevation measured along the runway centre line, and for those portions related to a clearway, if one exists, at the elevation of nearest runway end;</li>
            <li>Objects in the take-off flight path area which project above a plane surface having a 1.2 per cent slope and having a common origin with the take-off flight path area;</li>
            <li>Penetrations of the aerodrome obstacle limitation surfaces.</li>
          </ul>
          [Note.— Take-off flight path area are specified in Annex 4, 3.8.2. Aerodrome obstacle limitation surfaces are specified in Annex 14, Volume 1, Chapter 4.]
        </p>
        <p>
          <strong>Sec 5.3.3.4.10:</strong> For aerodromes regularly used by international civil aviation, obstacle data shall be provided for Area 4, for all runways where precision approach Category II or III operations have been established.
        </p>
      </div>

      <div className="tin-image">
        <img src={TINImage} alt="Terrain and Obstacle Data" className="tin-img" />
      </div>
    </div>
  );
}

export default About;
