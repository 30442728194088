import React, { useState, useEffect, useMemo } from 'react';
import './LandingPage.css';
import IGIthumbnail from '../images/Delhi_.jpeg.jpg';
import SCAthumbnail from '../images/Mumbai_5.jpg'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import banner1 from '../images/Delhi_Image_part1.jpg';
import banner2 from '../images/Delhi_Image_part2.jpg';
import banner3 from '../images/Mumbai_Image_1.jpg'
import banner4 from '../images/Mumbai_Image_2.jpg'
import INFO_IGI from './Info_aerodrome/IGI_Aerodrome'
import INFO_SANTA_CRUZ_AERODROME from './Info_aerodrome/Santa_Cruz_Aerodrome'


function LandingPage({ isAuthActive, OnSignInState }) {
  const [manuals, setManuals] = useState([]);
  const [subtitleIndex, setSubtitleIndex] = useState(0);
  const [currentBanner, setCurrentBanner] = useState(banner1); // Initial banner image
  const [showIGI, setShowIGI] = useState(false)
  const [showSC, setShowSC] = useState(false)
  const [displayedSubtitle, setDisplayedSubtitle] = useState(""); // State to store displayed subtitle

  const subtitles = useMemo(() => [
    "The data for IGI Aerodrome has been created using stereo satellite images and photogrammetry technology for a pilot area.",
    "The data for Santa Cruz Aerodrome has been created using data mining from all the revised NOC’s  issued for enhanced height through Aeronautical Studies for Santa Cruz Aerodrome in the years 2018,2019, 2020, 2021, 2022, 2023 as well as the obstacles published in the AIP.",
    "Air safety is critical to the national security and economy. Estates around airports have become a scarce, valuable resource for industry. Large airport projects such as new runways at Mumbai, Delhi and other upcoming new airports demonstrate the conflict potential between urban planning and air traffic operator’s interests.",
  ], []);

  const access_token_stored = localStorage.getItem('access_token');
  const navigate = useNavigate();

  useEffect(() => {
    const banners = [banner1, banner2, banner3, banner4];
    let bannerIndex = 0;

    const bannerInterval = setInterval(() => {
      // Change banner image every 15 seconds
      bannerIndex = (bannerIndex + 1) % banners.length;
      setCurrentBanner(banners[bannerIndex]);
    }, 5000);

    return () => {
      clearInterval(bannerInterval);
    };
  }, []);


  useEffect(() => {
    // Animate subtitle letter by letter
    const subtitleInterval = setInterval(() => {
      const currentSubtitle = subtitles[subtitleIndex];
      const currentLength = displayedSubtitle.length;

      if (currentLength < currentSubtitle.length) {
        // Add one more letter to displayed subtitle
        setDisplayedSubtitle(currentSubtitle.substring(0, currentLength + 1));
      } else {
        // Move to the next subtitle
        setSubtitleIndex((prevIndex) => (prevIndex + 1) % subtitles.length);
        setDisplayedSubtitle(""); // Reset displayed subtitle
      }
    }, 100); // Change the interval duration for animation speed

    return () => {
      clearInterval(subtitleInterval);
    };
  }, [subtitleIndex, displayedSubtitle, subtitles]);

  useEffect(() => {
    // Fetch manuals from the API
    const fetchManuals = async () => {
      try {
        const response = await fetch('https://www.prithvipraroop.net/backend/api/user/files/');
        const data = await response.json();
        setManuals(data);
      } catch (error) {
  
      }
    };

    fetchManuals();
  }, []);

  const handleViewClick = (aerodrome) => {
    if (access_token_stored) {
      navigate('/dashboard', { state: { selectedAerodrome: aerodrome } });
    } else {
      toast.error('Please sign in to view data.');
    }
  };

  const aboutpageclick = () => {
    navigate('/about');
  }

  const handleDownloadClick = (manual) => {
    if (!access_token_stored) {
      toast.error('Please sign in to download the file.');
    } else {
      const downloadLink = document.createElement('a');
      downloadLink.href = manual.file;
      downloadLink.download = manual.title;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const scrollToEtodInfo = () => {
    const etodInfoSection = document.querySelector('.etod-info');
    etodInfoSection.scrollIntoView({ behavior: 'smooth' });
  };

  const handleIGIChange = () => {
    setShowIGI(!showIGI);
  }

  const handleSCChange = () => {
    setShowSC(!showSC);
  }

  return (
    <div className={`landing-page ${isAuthActive ? 'auth-active' : ''}`}>
      <section className="banner" style={{ backgroundImage: `url(${currentBanner})` }}>
        <div className="container">
          <div className="banner-wrap">
            <h1 className="banner-title">
              Terrain and Obstacle Data based on Sec 5.3.3.4.5, CAR Series I Part I by DGCA for a pilot area of IGI Airport and Santa Cruz Aerodromes.
            </h1>
            <h3 className="banner-subtitle">
              {displayedSubtitle}
            </h3>
            <div className='Quick-button'>
              <button className="link-button" onClick={scrollToEtodInfo}>
                <i className="fa fa-eye"></i> View Demo
              </button>
              <button className="link-button" onClick={aboutpageclick}>
                <i className="fa fa-info-circle"></i> About Portal
              </button>
            </div>
          </div>
          <div className="background-switcher">
          <div className={`background-icon ${currentBanner === banner1 ? 'active' : ''}`} onClick={() => setCurrentBanner(banner1)}></div>
          <div className={`background-icon ${currentBanner === banner2 ? 'active' : ''}`} onClick={() => setCurrentBanner(banner2)}></div>
          <div className={`background-icon ${currentBanner === banner3 ? 'active' : ''}`} onClick={() => setCurrentBanner(banner3)}></div>
          <div className={`background-icon ${currentBanner === banner4 ? 'active' : ''}`} onClick={() => setCurrentBanner(banner4)}></div>
        </div>
        </div>
      </section>

      <section className="aerodrome-selection">
        <h2>Explore TOD Database</h2>
        <div className='underline'></div>
        <div className="container">
          <div className="options">
            <div className="card">
              {/* <div className="meet-tag">
                <i className="fa fa-check-circle"></i>
                <span>Meets ICAO Annex 15 standards</span>
              </div> */}
              <div className="thumbnail">
                <img src={SCAthumbnail} alt="Airport" />
                <div className="icon-container">
                  <button onClick={handleSCChange}>
                    {showSC && <i className="fa fa-times"></i>}
                    {!showSC && <i className="fa fa-info-circle"></i>}
                  </button>
                </div>
              </div>
              <div class="content">
                <div class="description">
                  <h3>Santa Cruz Aerodrome</h3>
                  <div className="datasets-count">Total Layers: 85</div>
                </div>
              </div>
              <div class="buttons">
         
                <button className="link-button" onClick={() => handleViewClick('Mumbai')}>
                  <i className="fa fa-map"></i> View
                </button>
                {showSC && <INFO_SANTA_CRUZ_AERODROME />}
              </div>
            </div>

            <div className="card">
              {/* <div className="meet-tag">
                <i className="fa fa-check-circle"></i>
                <span>Meets ICAO Annex 15 standards</span>
              </div> */}
              <div className="thumbnail">
                <img src={IGIthumbnail} alt="Airport" />
                <div className="icon-container">
                  <button onClick={handleIGIChange}>
                    {showIGI && <i className="fa fa-times"></i>}
                    {!showIGI && <i className="fa fa-info-circle"></i>}
                  </button>
                </div>
              </div>
              <div class="content">
                <div class="description">
                  <h3>IGI Aerodrome</h3>
                  <div className="datasets-count">Total Layers: 70</div>
                </div>
              </div>
              <div class="buttons">
                <button className="link-button" onClick={() => handleViewClick('New Delhi')}>
                  <i className="fa fa-map"></i> View
                </button>
                {showIGI && <INFO_IGI />}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='card-manual-section'>
        <h2>Downloads</h2>
        <div className='underline'></div>
        <div className="card-manual-div">
          {manuals.map((manual, index) => (
            <div className="card-manual" key={index}>
              <h3>{manual.title}</h3>
              <button title='download' onClick={() => handleDownloadClick(manual)}>
                <i className="fa fa-download"></i> download
              </button>
            </div>
          ))}
        </div>
      </section>

      <section className='card-manual-section'>
        <h2>Webinar</h2>
    <div className='underline'></div>
    <div className="card-manual-div">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/be_XE-ykvxQ?si=IRC2QEuAGJQlIoNF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

        
   </section>
      <section class="etod-info">
        <div class="container">
          <div class='description'>
            <h2 class="animated-text">What is TOD data portal?</h2>
            <span>“Like failure, chaos contains information that can lead to knowledge -- even wisdom. Like art.“ Toni Morrison</span>
  
        <h3>Aeronautical Data Chain</h3>
        <p>
          A key driver for the transition from AIS to AIM is the need to achieve an uninterrupted aeronautical data chain with no loss or corruption in information, in a pre-defined format and with guaranteed accuracy and integrity. The aeronautical data chain is defined as “a series of interrelated links wherein each link provides a function that facilitates the origination, transmission and use of aeronautical data for a specific purpose”.
        </p>
      
      <p>
Obstacles penetrating the Annexure 14 surfaces (specifically in the Approach Funnel and IHS ) may affect air safety if not properly controlled. TOD is required for monitoring and surveillance of obstacles for ensuring flight safety. An accurate digital database of Obstacles around the Aerodrome needs to be created and updated at regular intervals as per DGCA Guidelines.
</p>
          </div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/K78lUkL6CSw?si=jq0e5dNBFB7REmfg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          
        </div>
      </section>

      <div className="additional-info-container">
        <div className="contact-info">
          <h3>Contact Information</h3>
          <p>Address: New Delhi, Delhi 110020, IN</p>
          <p>Email: info@globalcoordinates.net</p>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
