/* Additional Page */
// This is an additional page added just above the import statements.
// You can include any code or comments related to this additional page here.

import React from 'react';
import './Footer.css'; // You can create a separate CSS file for the footer styles

function Footer() {
  return (
    <footer className="footer-container">


      <div className='footer-underline'></div>
      <div className="footer-text">
        &copy; {new Date().getFullYear()} Global Coordinates. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
